// @flow
import * as React from 'react'
import { graphql } from 'gatsby'

import { Section, Content, spacing, uppercase, Meta } from '../components/Theme'
import ClientList from '../components/ClientList'
import Category from '../components/Category'
import Header from '../components/Header'
import Layout from '../components/Layout'

type MainSchema = {
  fields: {
    slug: string
  },
  frontmatter: {
    title: string,
    introduction: string
  }
}

type CategorySchema = {
  edges: Array<{
    node: {
      id: string,
      fields: {
        slug: string
      },
      frontmatter: {
        title: string,
        tagline: string,
        tags: Array<string>,
        images: Array<{
          resize: {
            src: string
          }
        }>
      }
    }
  }>
}

type Props = {
  data: {
    experiencesItems: CategorySchema,
    experiencesMain: MainSchema,
    productsItems: CategorySchema,
    productsMain: MainSchema,
    experimentsItems: CategorySchema,
    experimentsMain: MainSchema,
    page: {
      frontmatter: {
        title: string,
        introduction: string,
        clientsTitle: string,
        clientsIntroduction: string,
        clients: Array<string>
      }
    }
  },
  location: any
}

export default ({ data, location }: Props) => {
  const categoryComponents = data.categories.edges.map(({ node }) => (
    <Category
      key={node.id}
      title={node.name}
      to={node.slug}
      items={node.projects ? node.projects.filter(p => p.featured) : []}
      introduction={node.introduction && node.introduction.introduction}
    />
  ))

  return (
    <Layout location={location}>
      <div>
        <Meta title={null} />
        <Header isHome introduction={data.page.frontmatter.introduction} />
        <div>{categoryComponents}</div>
        <Section>
          <Content
            css={`
              margin-top: ${spacing.xlarge};
            `}
          >
            <h2>
              <span className={uppercase}>
                {data.page.frontmatter.clientsTitle}
              </span>
            </h2>
            <p>{data.page.frontmatter.clientsIntroduction}</p>
            <ClientList clients={data.page.frontmatter.clients} />
          </Content>
        </Section>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query indexQuery($slug: String = "home") {
    categories: allContentfulCategory(sort: { fields: [order] }) {
      edges {
        node {
          id
          name
          slug
          introduction {
            introduction
          }

          projects: project {
            id
            title
            date
            slug
            tagline
            tags
            client
            featured

            category {
              slug
            }

            images {
              resize(width: 800, height: 350, cropFocus: LEFT, quality: 85) {
                src
              }
            }
          }
        }
      }
    }

    page: markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        clientsIntroduction
        clientsTitle
        clients
        title
        introduction
      }
    }
  }
`
