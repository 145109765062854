import * as React from 'react'
import styled from 'react-emotion'

type Props = {
  clients: Array<string>
}

const ClientList = styled.ul`
  display: flex;
  list-style-type: none;
  flex-wrap: wrap;
  margin: 0;
  @media screen and (max-width: 640px) {
    margin-bottom: 2em;
  }
`

const Client = styled.li`
  flex: 0 0 auto;
  max-width: 100%;
  font-size: 1.2em;
  margin-bottom: 0.3em;
  &:not(:last-of-type) {
    &:after {
      content: ' — ';
      color: rgb(195, 195, 200);
      margin: 0 0.25em;
      display: inline-block;
    }
  }
`

const ClientListComponent = (props: Props) => (
  <ClientList>
    {props.clients.map((client, index) => (
      <Client key={`client-${index}`}>{client}</Client>
    ))}
  </ClientList>
)

export default ClientListComponent
