// @flow
import * as React from 'react'
import { Link } from 'gatsby'
import styled from 'react-emotion'

import { Content, Lead, uppercase, colors, spacing } from '../components/Theme'
import Thumbnail from '../components/Thumbnail'

const ProjectGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const ViewLink = styled.span`
  font-weight: 300;
  color: ${colors.gray};
  &:hover {
    color: ${colors.black};
  }
`

const CategoryTitle = ({ children, to }) => (
  <h2>
    <Link to={to}>
      <span className={uppercase}>{children}</span>{' '}
      <ViewLink>–&nbsp;View all</ViewLink>
    </Link>
  </h2>
)

const Category = ({
  title,
  to,
  items,
  introduction
}: {
  title: string,
  to: string,
  items: any,
  introduction: ?string
}) => {
  return (
    <div>
      <Content
        css={`
          margin-top: ${spacing.xlarge};
        `}
      >
        <CategoryTitle to={to}>{title}</CategoryTitle>
        <Lead>
          <p>{introduction}</p>
        </Lead>
      </Content>
      <Content
        css={`
          margin-bottom: ${spacing.medium};
        `}
      >
        <ProjectGrid>
          {items
            .filter(node => {
              return node.images
            })
            .map(project => (
              <Thumbnail
                key={project.id}
                tags={project.tags || []}
                slug={`${project.category.slug}/${project.slug}`}
                title={project.title}
                image={project.images[0]}
                client={project.client}
                tagline={project.tagline}
              />
            ))}
        </ProjectGrid>
      </Content>
    </div>
  )
}

export default Category
