// @flow
import * as React from 'react'
import styled from 'react-emotion'
import { Link } from 'gatsby'

import { Lead, singleLine } from './Theme'

type Props = {
  slug: string,
  title: string,
  tags: Array<string>,
  tagline: string,
  client: string,
  image: {
    childImageSharp: {
      resize: {
        src: string
      }
    }
  }
}

type State = {
  hovered: boolean
}

const Thumbnail = styled.div`
  margin: 0 1em 0.5em 0;
  width: calc(50% - 0.5em);
  max-width: 800px;
  height: 240px;
  user-select: none;
  position: relative;
  overflow: hidden;
  &:nth-child(2n) {
    margin-right: 0;
  }
  @media screen and (max-width: 800px) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 1em;
  }
`

const ImageContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
`

const MoreInfo = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const Image = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  transition: transform 0.3s cubic-bezier(0, 0, 0, 1.4);
  pointer-events: none;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  transform: translateY(${props => (props.hovered ? '4em' : 0)});
  background-image: url(${props => props.image});
`

const Tags = styled.ul`
  list-style-type: none;
  display: flex;
  width: 100%;
  margin-left: 0;
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const Tag = styled.li`
  flex: 0 1 auto;
  font-size: 0.9em;
  color: rgb(180, 180, 180);
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const ProjectTitle = styled.h3`
  margin-bottom: 0.5em;
  margin-top: 1em;
  line-height: 1.3;
`

const Client = styled.li`
  font-size: 0.9em;
  color: rgb(70, 70, 90);
  text-transform: uppercase;
  &:after {
    content: ' — ';
    color: rgb(195, 195, 200);
    margin: 0 0.5em;
    display: inline-block;
  }
`

class Thumb extends React.Component<Props, State> {
  constructor() {
    super()
    this.state = { hovered: false }
  }

  handleMouseEnter = () => {
    this.setState({ hovered: true })
  }

  handleMouseLeave = () => {
    this.setState({ hovered: false })
  }

  render() {
    const { slug, title, image, tags, tagline, client } = this.props
    return (
      <Thumbnail
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        <Link
          css={`
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
          `}
          to={slug}
        >
          <ProjectTitle className={singleLine}>{title}</ProjectTitle>
          <ImageContainer>
            <MoreInfo>
              <Tags>
                <Client>{client}</Client>
                <Tag>{tags.join(' / ')}</Tag>
              </Tags>
              <Lead>
                <p
                  css={`
                    font-size: 1em;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  `}
                >
                  {tagline}
                </p>
              </Lead>
            </MoreInfo>

            <Image image={image.resize.src} hovered={this.state.hovered} />
          </ImageContainer>
        </Link>
      </Thumbnail>
    )
  }
}

export default Thumb
